import React, { lazy } from "react";

// project import
import MainLayout from "./../layout/MainLayout";
import CommonLayout from "./../layout/CommonLayout";
import Loadable from "./../components/Loadable";
import Profile from "../pages/Profile";

// pages routing
const MaintenanceError = Loadable(
  lazy(() => import("./../pages/Maintenance/404"))
);
const MaintenanceError500 = Loadable(
  lazy(() => import("./../pages/Maintenance/500"))
);

const ProductList = Loadable(lazy(() => import("../pages/ProductList")));

const ListingProduct = Loadable(lazy(() => import("../pages/ListingProduct")));

const ProductDetail = Loadable(
  lazy(() => import("../pages/ListingProduct/ProductDetail"))
);
const AddProduct = Loadable(lazy(() => import("../pages/AddProduct")));

const PurchaseList = Loadable(lazy(() => import("../pages/PurchaseList")));

const Landing = Loadable(lazy(() => import("../pages/Landing")));
const Imprint = Loadable(lazy(() => import("../pages/Landing/imprint")));
const DataProtection = Loadable(lazy(() => import("../pages/Landing/dataProtection")));

const Login = Loadable(lazy(() => import("../pages/Login")))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    children: [
        {
            //path: "/", element: <MainLayout />,
            path: "/",
            //element: <MainLayout />,
            element: <Landing />,
            children: [
                {
                    path: "",
                    element: <ListingProduct />,
                },
                {
                    path: "landing",
                    element: < Landing /> ,
                },
                {
                    path: "listing",
                    element: <ListingProduct />,
                },
                {
                    path: "products/:id",
                    element: <ProductDetail />,
                },
                {
                    path: "purchases/:id",
                    element: <ProductDetail />,
                },
                {
                    path: "add-product",
                    element: <AddProduct />,
                },
                {
                    path: "add-product/:id",
                    element: <AddProduct />,
                },
                {
                    path: "product-list",
                    element: <ProductList />,
                },
                {
                    path: "profile",
                    element: <Profile />,
                },
                {
                    path: "purchase-list",
                    element: <PurchaseList />,
                },

            ],
        },
        {
            path: "/auth",
            element: <CommonLayout />,
            children: [
                {
                    path: "login",
                    element: <Login />,
                },
            ]
        },
        {
            path: "/maintenance",
            element: <CommonLayout />,
            children: [
                {
                    path: "404",
                    element: <MaintenanceError />,
                },
                {
                    path: "500",
                    element: <MaintenanceError500 />,
                },
            ],
        },
        {
            path: "landing",
            element: <Landing />,
        },
        {
            path: "Imprint",
            element: <Imprint />,
        },
        {
            path: "DataProtection",
            element: <DataProtection />,
        }
    ],
};

export default MainRoutes;
